export default [
  {
    title: 'Giriş',
    icon: 'HomeIcon',
    route: 'home',
    resource: 'DASHBOARD',
    action: 'read',
  },
  {
    title: 'Online Ödeme',
    icon: 'CreditCardIcon',
    route: 'PaymentSearch',
    resource: 'PAYMENT',
    action: 'read',
  },
  {
    header: 'Ödemeler',
    icon: 'ArchiveIcon',
    children: [
      {
        title: 'Ödeme Geçmişi',
        icon: 'ArchiveIcon',
        route: 'PaymentHistory',
        resource: 'PAYMENT_HISTORY_LIST',
        action: 'read',
      },
      {
        title: 'Ödeme Takvimi',
        icon: 'CalendarIcon',
        route: 'PaymentCollections',
        resource: 'PAYMENT_COLLECTION_LIST',
        action: 'read',
      },
      {
        title: 'Ödeme İtirazları',
        icon: 'ListIcon',
        route: 'DisapprovalPayments',
        resource: 'DISAPPROVAL_PAYMENTS',
        action: 'read',
      },
    ],
    action: 'read',
  },
  {
    header: 'Cari İşlemler',
    icon: 'BookIcon',
    children: [
      {
        title: 'İşlemler',
        icon: 'BookIcon',
        route: 'CurrentAccounts',
        resource: 'CURRENT_ACCOUNTS_LIST',
        action: 'read',
      },
      {
        title: 'Yeni Cari Hesap',
        icon: 'PlusCircleIcon',
        route: 'CurrentAccountSearch',
        resource: 'CURRENT_ACCOUNTS_ADD',
        action: 'read',
      },
    ],
    action: 'read',
  },
  // {
  //   title: 'Bize Ulaşın',
  //   icon: 'MapPinIcon',
  //   route: 'CompanyView',
  //   resource: 'COMPANY_VIEW',
  //   action: 'read',
  // },
  {
    title: 'Müşteriler',
    icon: 'UsersIcon',
    route: 'Customers',
    resource: 'CUSTOMER_CARD_LIST',
    action: 'read',
  },
  {
    header: 'Ayarlar',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'Kullanıcılar',
        icon: 'UsersIcon',
        action: 'read',
        children: [
          {
            title: 'Kullanıcı Listesi',
            route: 'Users',
            icon: 'UserIcon',
            resource: 'USERS_LIST',
            action: 'read',
          },
          {
            title: 'Kullanıcı Seviyeleri',
            route: 'UserTypes',
            icon: 'ShieldIcon',
            resource: 'USER_TYPES_LIST',
            action: 'read',
          },
        ],
      },
      {
        title: 'Banka Pos Ayarları',
        route: 'PosConfigs',
        icon: 'ServerIcon',
        resource: 'POS_CONFIGS_LIST',
        action: 'read',
      },
      {
        title: 'SMTP Ayarları',
        route: 'Smtpconfig',
        icon: 'MailIcon',
        resource: 'SMTP_CONFIG',
        action: 'read',
      },
      {
        title: 'Firma Bilgileri',
        route: 'Company',
        icon: 'BookIcon',
        resource: 'COMPANY_EDIT',
        action: 'read',
      },
      {
        title: 'Duyurular',
        route: 'Announcements',
        icon: 'BellIcon',
        resource: 'ADMIN_ANNOUNCEMENTS',
        action: 'read',
      },
    ],
  },
  {
    header: 'Raporlar',
    icon: 'PieChartIcon',
    children: [
      {
        title: 'Güncel Durum',
        route: 'CurrentStatus',
        icon: 'BarChart2Icon',
        resource: 'REPORTS_CURRENT_STATUS',
        action: 'read',
      },
      {
        title: 'Bankalar',
        route: 'BanksReport',
        icon: 'PieChartIcon',
        resource: 'REPORTS_BANKS',
        action: 'read',
      },
      {
        title: 'Müşteriler',
        route: 'CustomersReport',
        icon: 'BarChartIcon',
        resource: 'REPORTS_CUSTOMERS',
        action: 'read',
      },
      {
        title: 'Temsilciler',
        route: 'UsersReport',
        icon: 'ListIcon',
        resource: 'REPORTS_USERS',
        action: 'read',
      },
    ],
  },
]
